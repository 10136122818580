import { createContext, useState, useContext } from 'react';
import {fetchMcq, fetchMoremcq} from '../Config/api';
import { useNotFoundContext } from './NotFound';
import { useStatuscodeContext } from './StatusCode';

const McqContext = createContext();

export function McqProvider({ children }) {
    const [mcq, setMcq] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { pagefoundhandler } = useNotFoundContext();  
    const { statuscodehandler } = useStatuscodeContext(); 
   
      // Fetch all blogs data
      const fetchAllMcqHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchMcq();
            setMcq(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };

    // Fetch More videos data
    const fetchMoremcqHandler = async (limit) => {
      setLoading(true);
      try {
          const data = await fetchMoremcq(limit);
          setMcq((prev) => [...prev, ...data.data]); // Set branches data from response
          pagefoundhandler(data.data.statusCode)
          setError(null);
      } catch (err) {
          console.error("Error fetching branches:", err);
          setError("Failed to load branches");
      } finally {
          setLoading(false);
      }
  };

    return (
      <McqContext.Provider value={{mcq, loading, error, fetchAllMcqHandler, fetchMoremcqHandler}}>
        {children}
      </McqContext.Provider>
    );
  }
  
  export function useMcqContext() {
    return useContext(McqContext);
  }