import { createContext, useState, useContext } from 'react';

const ContentContext = createContext();

export function ContentProvider({ children }) {
    const [Content, setContent] = useState(null);
  
    return (
      <ContentContext.Provider value={{ Content, setContent }}>
        {children}
      </ContentContext.Provider>
    );
  }
  
  export function useContentContext() {
    return useContext(ContentContext);
  }