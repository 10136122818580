/**
 * SEO component to manage and set SEO metadata for the application.
 * 
 * This component uses the `react-helmet-async` library to set the page title and meta description.
 * It also utilizes a custom SEO context to manage the SEO state.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {string} props.seot1 - The SEO title to be set
 * @param {string} props.seot2 - The SEO description to be set
 * 
 * @example
 * <SEO seot1="Home Page" seot2="Welcome to the home page" />
 * 
 * @returns {JSX.Element} The rendered component
 * 
 * @function
 * @name SEO
 * 
 * @track
 * - Tracks changes to `seot1` and `seot2` props and updates the SEO context accordingly.
 * - Logs the current and updated SEO title and description to the console.
 */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSeoContext } from '../../../Context/SEOManagement';


function SEO({seot1,seot2}) {

    const {setSEOdescription, setSEOtitle, seotitle, seodescription} = useSeoContext();

    console.log("seo", seot1, seotitle)
    console.log("seodescription", seot2, seodescription)

    useEffect(()=>{
        setSEOtitle(seot1)
     setSEOdescription(seot2)
    },[seot1, seot2])
  return (
    <>
     <Helmet>
        <title>{seot1}</title>
        <meta name="description" content={seodescription} />
      </Helmet>
    </>
  )
}

export default SEO;