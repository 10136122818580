import React from 'react';

function Instructor() {
  return (
    <>
        <div
                    className="tab-pane fade show active"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                  >
<div className="course-tab-content">
                      <div className="course-instructor">
                        <div className="thumbnail">
                          <img
                            src="assets/images/course/author-01.png"
                            alt="Author Images"
                          />
                        </div>
                        <div className="author-content">
                          <h6 className="title">Edward Norton</h6>
                          <span className="subtitle">Founder & CEO</span>
                          <p>
                            Consectetur adipisicing elit, sed do eiusmod tempor
                            incididunt labore et dolore magna aliqua enim minim
                            veniam quis nostrud exercitation ulla mco laboris
                            nisi ut aliquip ex ea commodo consequat. duis aute
                            irure dolor in reprehenderit in voluptate.
                          </p>
                          <ul className="social-share">
                            <li>
                              <a href="#">
                                <i className="icon-facebook"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="icon-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="icon-linkedin2"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="icon-youtube"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
    
    
    </>
  )
}

export default Instructor;