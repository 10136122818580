import React from 'react';
import notimg6 from "../../../assets/images/others/map-shape-2.png"
import { Link } from 'react-router-dom';
import UseAnalytics from '../../GoogleAnalytics/UseAnalytics';
// import img from "assets/images/banner/Camning Soon.png"

function Coming_SoonPage() {
  return (
    <>
    <UseAnalytics />
                  <section className="section-gap-equal error-page-area">
            <div className="container">
                <div className="edu-error">
                    <div className="content">
                        <h3 className="title" style={{color:"#CA0000"}}>PLEASE WAIT..</h3>
                        <h4 className="subtitle">We are working on it</h4>
                        <Link to="/" className="edu-btn text-white"><i className="icon-west text-white"></i>Back to Homepage</Link>
                    </div>
                </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1">
                    <img src={notimg6} alt="Shape"/>
                </li>
            </ul>
        </section>
    </>
  )
}

export default Coming_SoonPage;