import React, { useState } from 'react';
import { useBranchContext } from '../../../Context/Branchapi';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useToggleContext } from '../../../Context/Togglemanagement';
import { useBranchwiseidContext } from '../../../Context/BranchwiseCourse';

function MultiLevelDropdown() {
         const { branches, branchDetails, coursebybranch, fetchAllBranchesHandler, fetchBranchByUrlHandler } =
         useBranchContext();

  const [isOpen, setIsOpen] = useState(false);
  const { branchwiseCourseid, branchwiseCourseidhandler  } =
              useBranchwiseidContext(); 
  const [activeBranchUrl, setActiveBranchUrl] = useState(null); // Track the active branch URL
  const [activeCourseUrl, setActiveCourseUrl] = useState(null); // Track the active branch URL

      const {mobilemenuid, isVisible, ToggleopenHandler, TogglecloseHandler, menuidhandler} = useToggleContext();

  useEffect(()=>{
    fetchAllBranchesHandler()
    

 },[])

 useEffect(() => {
    if (isVisible) {
      setActiveBranchUrl(null); // Reset the active branch URL when the dropdown closes
    }
  }, [isVisible]);

  // const toggleDropdown1 = () => {
  //   setIsOpen(!isOpen); 
  // };
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
 
    // fetchBranchByUrlHandler(branch)
    
  };

  const handledropdown = (branchUrl) => {

    fetchBranchByUrlHandler(branchUrl)
    setActiveBranchUrl(branchUrl); // Set the active branch URL when a branch is selected
    // branchwiseCourseidhandler(branchDetails.ID)
    // TogglecloseHandler()
} 

  useEffect(()=>{
    branchwiseCourseidhandler(branchDetails.ID)
  },[branchDetails.ID])

const handlecourseactive =(courseurl)=>{
  setActiveCourseUrl(courseurl)
}

useEffect(() => {
  $(".reponsive-link").on("click", () => {
    $(".dropdown").addClass("dropdown-menu-after-click"); 
  });
}, []);

  return (
<>
      {/* {!isOpen && ( */}
        <ul className={`${isVisible === 'hide' ? 'dropdown-menu dropdown-menu-after-click custom-single-drop responsive-custom-multilevel-dropdown' : 'responsive-custom-multilevel-dropdown dropdown-menu custom-single-drop'}`}>
         {branches.slice(1).map((branch, index) =>( <li>
            <div className="sub-dropdown" onMouseOver={()=>handledropdown(branch.branchurl)}>
              <Link to="#" onClick={toggleDropdown} className={`sub-dropdown-toggle fs-3 fw-bold ${activeBranchUrl === branch.branchurl ? 'sub-dropdown-toggle-active' : ''}`}>
              {branch.displayname}
              </Link>
              <ul className="sub-dropdown-menu custom-single-drop">
              {
                                                        coursebybranch.map((course)=>(
                                                            <li style={{whiteSpace:'nowrap'}} ><Link to={`/course-details/${course.courseurl}`} className={`px-3 ${activeCourseUrl === course.courseurl ? 'sub-dropdown-toggle-active-course' : 'sub-dropdown-toggle-course'}`} onMouseOver={()=>handlecourseactive(course.courseurl)} onClick={() => TogglecloseHandler()} style={{whiteSpace:'nowrap'}}>{course.coursename}</Link></li>
                                                        ))
                                                    }
              </ul>
            </div>
          </li>))}
        </ul>
      {/* )} */}
      </>

  );
}

export default MultiLevelDropdown;