import React, { useEffect } from "react"; // Import the useEffect hook from React
import { useParams } from "react-router-dom"; // Import the useParams hook from react-router-dom
import img1 from "../../../assets/images/others/shape-81.png"; // Import the shape-81 image
import img2 from "../../../assets/images/others/shape-65.png"; // Import the shape-65 image
import img3 from "../../../assets/images/cta/shape-08.png"; // Import the shape-08 image
import img4 from "../../../assets/images/others/shape-79.png"; // Import the shape-79 image
import { useBackgroundContext } from "../../../Context/BackgroundChange"; // Import the useBackgroundContext
import CustomEnquery from "../../Form/CustomEnquery"; // Import the CustomEnquery component
import { useArticleContext } from "../../../Context/Articleapi"; // Import the useArticleContext
import SEO from "../../SeoWidget/SEO/SEO"; // Import the SEO component
import { FaHome } from "react-icons/fa"; // Import the FaHome icon
import UseAnalytics from "../../GoogleAnalytics/UseAnalytics"; // Import the UseAnalytics component
import { useTrackingManagementContext } from "../../../Context/TrackingManagement"; // Import the useTrackingManagementContext
import { useQueryContext } from "../../../Context/QueryManagement";

// ArticleDetails component to display the detailed view of an article
function Article() {
  const { articleurl } = useParams(); // Extracts article URL from the route parameters
  const { backgroundStyle } = useBackgroundContext(); // Retrieves background style from context
  const {
    articles,
    articleDetails,
    fetchAllArticlesHandler,
    fetchOldArticleByUrlHandler,
  } = useArticleContext(); // Fetches articles and article details from context

  const { handleUrlChange } = useTrackingManagementContext(); // Destructure the trackingid and handleButtonClick functions

      const { getPagename } = useQueryContext(); // Destructure the getPagename, MetaDetails, MetaDetailsHandler, and pagename from the useQueryContext

  // Fetch article details when the article URL changes
  useEffect(() => {
    fetchOldArticleByUrlHandler(articleurl);
    getPagename(articleurl)
  }, [articleurl]);

  // Fetch all articles for the "Other Articles" section when the article URL changes
  useEffect(() => {
    fetchAllArticlesHandler(articleurl);
  }, [articleurl]);

  // Extract article details with fallbacks
  const branchName = articleDetails?.question || "..";
  const addressHTML = articleDetails?.answer || "";
  const metatitle = articleDetails?.question || "";
  const metadescription = articleDetails?.question || "";

  useEffect(() => {
    setTimeout(() => {
      handleUrlChange();
    }, 2000); // Set a delay of 2 seconds
  }, []); // Trigger URL change handling with a delay

  return (
    <>
      {/* Analytics tracking */}
      <UseAnalytics />

      {/* SEO component for dynamic metadata */}
      <SEO seot1={metatitle} seot2={metadescription} />

      {/* Breadcrumb area with dynamic background */}
      <div className="edu-breadcrumb-area breadcrumb-style-8">
        <div className="breadcrumb-right-wrapper" style={backgroundStyle}>
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="page-title">
                <h1 className="title">{branchName}</h1>
              </div>
            </div>
          </div>
        </div>

        {/* Decorative shapes */}
        <ul className="shape-group">
          <li className="shape-6 scene">
            <img data-depth="2" src={img1} alt="shape" />
          </li>
          <li className="shape-7 scene">
            <img data-depth="-2" src={img2} alt="shape" />
          </li>
          <li className="shape-8">
            <img src={img3} alt="shape" />
          </li>
          <li className="shape-9 scene">
            <img data-depth="-2" src={img4} alt="shape" />
          </li>
        </ul>
      </div>

      {/* Article details section */}
      <section
        className="edu-section-gap course-details-area"
        style={{ padding: "60px 0px 120px" }}
      >
        <div className="container">
          <div className="row row--30">
            {/* Main content */}
            <div className="col-lg-12">
              <div className="course-details-content course-details-7">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                  >
                    <div className="course-tab-content">
                      <div className="course-overview">
                        {/* Breadcrumb navigation */}
                        <p
                          className="text-dark"
                          style={{
                            fontFamily: "Arial, sans-serif",
                            border: "1px solid #CCC",
                            background: "#F2F4F7",
                            padding: "10px",
                          }}
                        >
                          <a href="/" style={{ color: "green" }}>
                            <FaHome />
                          </a>{" "}
                          /{" "}
                          <a href="/articles">Articles</a>{" "}
                          / {branchName}
                        </p>
                
                        <h5 className="course-price fs-1 py-3 text-danger fw-bold">
                          <span
                            style={{
                              borderBottom: "1px solid #CA0000",
                              paddingBottom: "7px",
                              color: "#CA0000",
                              display: "inline-block",
                              fontFamily: "Arial, sans-serif",
                            }}
                          >
                            {branchName}
                          </span>
                        </h5>
                  
                        <p dangerouslySetInnerHTML={{ __html: addressHTML }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            {/* <div className="col-lg-4">
              <div className="course-sidebar-3 sidebar-top-position">
              
                <div className="edu-course-widget widget-course-summery">
                  <div className="inner">
                    <CustomEnquery />
                  </div>
                </div>
              </div>
              <div style={{height: "15px"}}></div>
              <div className="col-lg-12 mt-0 pt-5">
                <div className="contact-form form-style-2 px-0 py-0">
                  <div className="edu-blog-widget p-0 widget-latest-post mt-5">
                    <div className="inner">
                      <h4
                        className="widget-title highlight-custom-background text-white py-5 px-5"
                        style={{
                          borderTopRightRadius: "5px",
                          borderTopLeftRadius: "5px",
                        }}
                      >
                        <i className="icon-14"></i>&nbsp;&nbsp;OTHER ARTICLES
                      </h4>
                      <div className="content latest-post-list px-5 pb-5">
                        <PopularCard
                          articles={articles}
                          branchNamex={branchName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      {/* Additional bottom gap */}
      <div className="gap-bottom-equal" style={{ paddingBottom: "5px" }}></div>
    </>
  );
}

export default Article;
