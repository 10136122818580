import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

// Create a Context for the validation
const ValidationContext = createContext();

// Utility function to set cookies
const setCookie = (key, value) => {
  if (typeof window !== 'undefined') {
    const { hostname } = window.location;
    if (hostname === 'localhost' || hostname === 'www.sriramsias.com') {
      Cookies.set(key, value, { path: '/' });
    }
  }
};

// Create a provider component
export const ValidationProvider = ({ children }) => {
    const [formValues, setFormValues] = useState({ name: "", email: "", contact: "", query: "" });
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
      if (!formValues.query) {
        setFormValues((prevValues) => ({
          ...prevValues,
          query: "Preparing For UPSC CSE 2025",
        }));
      }
    }, [formValues.query]);

    const validateField = (name, value) => {
        let error = "";
        if (!value) {
          error = "This field is required.";
        } else {
          switch (name) {
            case "name":
              setCookie('trackname', value);
              break;
            case "email":
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(value)) {
                error = "Invalid email format.";
              } else {
                setCookie('trackemail', value);
              }
              break;
            case "contact":
              const contactRegex = /^[0-9]{10}$/;
              if (!contactRegex.test(value)) {
                error = "Contact number must be 10 digits.";
              } else {
                setCookie('trackcontact', value);
              }
              break;
            default:
              break;
          }
        }
        return error;
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    
        if (formErrors[name]) {
          // Revalidate the field if there's an existing error
          const error = validateField(name, value);
          setFormErrors({ ...formErrors, [name]: error });
        }

        // Set cookies for updated values
    setCookie(name, value);
      };

    return (
        <ValidationContext.Provider value={{ formValues, formErrors, setFormErrors, setFormValues, validateField, handleInputChange }}>
            {children}
        </ValidationContext.Provider>
    );
};

// Custom hook to use the validation context
export const useValidation = () => {
    return useContext(ValidationContext);
};