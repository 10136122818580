import { createContext, useState, useContext } from 'react';
import { fetchAboutByUrl, fetchAboutsecctionbyHomepage, fetchfaculty} from '../Config/api';

const AboutContext = createContext();

export function AboutProvider({ children }) {
    const [aboutDetails, setAboutDetails] = useState({});
    const [aboutsectionDetails, setAboutsectionDetails] = useState({});
    const [faculty, setfaculty] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

        // Fetch single About data based on URL 
        const fetchAboutByUrlHandler = async (aboutUrl) => {
          setLoading(true);
          try {
              const data = await fetchAboutByUrl(aboutUrl);
              setAboutDetails(data.data[0])
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      };

        // Fetch single About data based on URL 
        const fetchAboutSectionByHomeHandler = async () => {
          setLoading(true);
          try {
              const data = await fetchAboutsecctionbyHomepage();
              setAboutsectionDetails(data.data[0])
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      }; 

        // Fetch Faculty data based on URL 
        const fetchFacultyHandler = async () => {
          setLoading(true);
          try {
              const data = await fetchfaculty();
              setfaculty(data.data)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      }; 
  
    return (
      <AboutContext.Provider value={{ aboutDetails, aboutsectionDetails, faculty, loading, error, fetchAboutByUrlHandler, fetchFacultyHandler, fetchAboutSectionByHomeHandler}}>
        {children}
      </AboutContext.Provider>
    );
  }
  
  export function useAboutContext() {
    return useContext(AboutContext);
  }