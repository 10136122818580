import React, { useEffect } from "react"; // Import the useEffect hook from React 
import { useBooksContext } from "../../../Context/Booksapi";  // Import the useBooksContext
import UseAnalytics from "../../GoogleAnalytics/UseAnalytics"; // Import the UseAnalytics component
import { useTrackingManagementContext } from "../../../Context/TrackingManagement"; // Import the useTrackingManagementContext
import { useQueryContext } from "../../../Context/QueryManagement"; // Import the useQueryContext
import SEO from "../../SeoWidget/SEO/SEO"; // Import the SEO component
import { Link } from "react-router-dom";  // Import the Link component

function AppscMagazinePage() {
  const { epicDetails, fetchepicmagazineByUrlHandler } =
    useBooksContext();  // Destructure the epicDetails and fetchepicmagazineByUrlHandler from the useBooksContext

  const { trackingid, handleUrlChange } = useTrackingManagementContext(); 

  const { getPagename, MetaDetails, MetaDetailsHandler, pagename } =
    useQueryContext();  // Destructure the getPagename, MetaDetails, MetaDetailsHandler, and pagename from the useQueryContext

  useEffect(() => {
    getPagename("appsc-magazine");
  }, []); // Set the page name to "appsc-magazine"

  useEffect(() => {
    fetchepicmagazineByUrlHandler();
  }, []); // Fetch epic magazine data on component mount

  // Trigger URL change tracking after a timeout
  useEffect(() => {
    setTimeout(() => {
      handleUrlChange();
    }, 2000); // Set a delay of 2 seconds
  }, []); // Trigger URL change handling with a delay

  // Fetch meta details for "Contact Us" page on component mount
  useEffect(() => {
    MetaDetailsHandler("appsc-magazine");
  }, []); // Set the MetaDetailsHandler to "appsc-magazine"

  // Extract meta title and description from context
  const metatitle = MetaDetails?.metatitle || ""; // Set the metatitle to the MetaDetails metatitle or an empty string
  const metadescription = MetaDetails?.metadescription || ""; // Set the metadescription to the MetaDetails metadescription or an empty string

  return (
    <>
      <UseAnalytics />  {/* Use the Google Analytics component */}
      <SEO seot1={metatitle} seot2={metadescription} /> {/* Use the SEO component */}
      <div className="section-title section-center pt-5"> 
        <h2 className="title custom-font-family">APPSC Magazine</h2>  {/* Set the title to "APPSC Magazine" */}
        <span className="shape-line"> 
          <i className="icon-19"></i> {/* Use the icon-19 icon */}
        </span> 
      </div>
      <div className="container pb-5">  {/* Set the container with padding bottom of 5 */}
        <div className="row"> {/* Set the row */}
          

          {epicDetails.map((epicmagazine, index) => (
            <div
              className="col-lg-3 p-5"
              id={trackingid}
              key={index}
              data-button-id={`Click on the button '${epicmagazine.headline}' on the ${pagename} Page at URL: ${window.location.href}.`}  // Set the data-button-id attribute
            > {/* Set the column with padding of 5 */}
              <div className="card-books">
                <img src={epicmagazine.coverpic} /> {/* Set the cover picture */}
                <div className="card__content"> {/* Set the card content */}
                  <p className="card__title text-dark fw-bold custom-font-family">  {/* Set the card title */}
                    {epicmagazine.headline}
                  </p>  {/* Set the headline */}
                  <div className="course-overview py-5 pb-0 d-flex justify-content-center  mt-5"> {/* Set the course overview */}
                    <Link // Set the Link component
                      to={`${epicmagazine.fileurl}`}  
                      target="_blank"  
                      className="fw-bold edu-btn custom-font-family text-white"
                      style={{
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      VIEW DETAILS
                    </Link>   {/* Set the button text to "VIEW DETAILS" */}
                  </div>
                </div>
              </div>
            </div>
          ))} {/* Map through the epicDetails array */}
        </div>
      </div>
    </>
  );
}

export default AppscMagazinePage;
