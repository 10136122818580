import { createContext, useState, useContext } from 'react';
import { fetchAllBranches, fetchBranchByUrl } from '../Config/api';

const BranchContext = createContext();

export function BranchProvider({ children }) {
    const [branches, setBranches] = useState([]);
    const [branchDetails, setBranchDetails] = useState({});
    const [coursebybranch, setCoursebybranch] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   
      // Fetch all branches data 
      const fetchAllBranchesHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchAllBranches();
            setBranches(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };

    // Fetch single branch data based on URL 
    const fetchBranchByUrlHandler = async (branchUrl) => {
        setLoading(true);
        try {
            const data = await fetchBranchByUrl(branchUrl);
            setBranchDetails(data.data.branch)
            setCoursebybranch(data.data.courses)
            console.log(data)
            // return data.data; // Return branch data from response
        } catch (err) {
            console.error("Error fetching branch by URL:", err);
            setError("Failed to load branch by URL");
            return null;
        } finally {
            setLoading(false);
        }
    }; 
  
    return (
      <BranchContext.Provider value={{ branches, branchDetails, coursebybranch, loading, error, fetchAllBranchesHandler , fetchBranchByUrlHandler }}>
        {children}
      </BranchContext.Provider>
    );
  }
  
  export function useBranchContext() {
    return useContext(BranchContext);
  }