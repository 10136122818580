import { createContext, useState, useContext } from 'react';

const SeoContext = createContext();

export function SeoProvider({ children }) {
    const [seotitle, setSEOtitle] = useState("");
    const [seodescription, setSEOdescription] = useState("");
  
    return (
      <SeoContext.Provider value={{ seotitle, seodescription , setSEOtitle , setSEOdescription  }}>
        {children}
      </SeoContext.Provider>
    );
  }
  
  export function useSeoContext() {
    return useContext(SeoContext);
  }