import { createContext, useState, useContext } from 'react';
import { fetchNavbarDetails } from '../Config/api';

const NavContext = createContext();

export function NavProvider({ children }) {
    const [Nav, setNav] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   
      // Fetch all blogs data
      const fetchAllNavHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchNavbarDetails();
            setNav(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };
  
    return (
      <NavContext.Provider value={{ Nav, loading, error, fetchAllNavHandler}}>
        {children}
      </NavContext.Provider>
    );
  }
  
  export function useNavContext() {
    return useContext(NavContext);
  }