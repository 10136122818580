import React from 'react'
// Importing a component to display the content of the branch home page
import BranchHomeCompoment from '../../PageCommonSection/CommonPageBranchSection/CommonPageBranchSection'
// Importing a custom hook for Google Analytics
import UseAnalytics from '../../GoogleAnalytics/UseAnalytics'
// Importing a component for managing SEO metadata
import SEO from '../../SeoWidget/SEO/SEO';
import { useEffect } from 'react';
// Importing context for managing query data
import { useQueryContext } from '../../../Context/QueryManagement';
// Importing context for managing tracking data
import { useTrackingManagementContext } from '../../../Context/TrackingManagement';

function BranchPage() { 
    // Extracting functions and state from the QueryManagement context
    const { getPagename, MetaDetails, MetaDetailsHandler } = useQueryContext();

    // Extracting a function from the TrackingManagement context to handle URL changes
    const { handleUrlChange } = useTrackingManagementContext();
  
    // Effect to set the page name for analytics or tracking when the component mounts
    useEffect(() => {
      getPagename("Branches"); // Sets the page name to "Branches"
    }, []); // Empty dependency array ensures this runs only once after the component mounts
  
    // Effect to update the metadata for the page when the component mounts
    useEffect(() => { 
        MetaDetailsHandler("Branches"); // Sets metadata for the "Branches" page
    }, []); // Empty dependency array ensures this runs only once
  
    // Effect to trigger URL update in the tracking context after a 2-second delay
    useEffect(() => {
         setTimeout(() => {
           handleUrlChange(); // Updates URL for tracking purposes
         }, 2000); // Delay of 2 seconds
       }, []); // Empty dependency array ensures this runs only once after the component mounts
  
    // Extracting metadata details (title and description) with fallback values
    const metatitle = MetaDetails?.metatitle || ""; // Fallback to an empty string if `metatitle` is not defined
    const metadescription = MetaDetails?.metadescription || ""; // Fallback to an empty string if `metadescription` is not defined
  
    return (
      <>
        {/* SEO component to dynamically set metadata for the page */}
        <SEO seot1={metatitle} seot2={metadescription} />
        {/* Google Analytics component for tracking page analytics */}
        <UseAnalytics />
        {/* Main content component for the branch page */}
        <BranchHomeCompoment />
      </>
    )
}

// Exporting the BranchPage component as default for use in other parts of the app
export default BranchPage
