import { createContext, useState, useContext } from 'react';
import { fetchAllBlogs, fetchBlogByUrl, fetchMoreBlogs } from '../Config/api';
import { useNotFoundContext } from './NotFound';
import { useStatuscodeContext } from './StatusCode';

const BlogsContext = createContext();

export function BlogsProvider({ children }) {
    const [blogs, setBlogs] = useState([]);
    const [blogDetails, setBlogDetails] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { pagefoundhandler } = useNotFoundContext();  
    const { statuscodehandler } = useStatuscodeContext(); 
   
      // Fetch all blogs data
      const fetchAllBlogsHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchAllBlogs();
            setBlogs(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };

        // Fetch single blog data based on URL 
        const fetchBlogByUrlHandler = async (blogUrl) => {
          setLoading(true);
          try {
              const data = await fetchBlogByUrl(blogUrl);
              if(data.statusCode === 200){
                  setBlogDetails(data.data[0])
              }
              pagefoundhandler(data.data.statusCode)
              statuscodehandler(data.data.statusCode)
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      }; 

      // Fetch More blogs data
      const fetchMoreBlogsHandler = async (limit) => {
        setLoading(true);
        try {
            const data = await fetchMoreBlogs(limit);
            setBlogs((prev) => [...prev, ...data.data]); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };
  
    return (
      <BlogsContext.Provider value={{ blogs, blogDetails, loading, error, fetchAllBlogsHandler, fetchBlogByUrlHandler, fetchMoreBlogsHandler}}>
        {children}
      </BlogsContext.Provider>
    );
  }
  
  export function useBlogsContext() {
    return useContext(BlogsContext);
  }