import { createContext, useState, useContext } from 'react';
import { fetchPageByurl} from '../Config/api';

const PageContext = createContext();

export function PageProvider({ children }) {
    const [pageDetails, setPageDetails] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

        // Fetch single About data based on URL 
        const fetchPageByUrlHandler = async (pageUrl) => {
          setLoading(true);
          try {
              const data = await fetchPageByurl(pageUrl);
              setPageDetails(data.data[0])
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      };
  
    return (
      <PageContext.Provider value={{ pageDetails, loading, error, fetchPageByUrlHandler}}>
        {children}
      </PageContext.Provider>
    );
  }
  
  export function usePageContext() {
    return useContext(PageContext);
  }