import React, { useEffect, useState, useCallback, useMemo, Suspense } from "react"; // Import the useEffect, useState, useCallback, and useMemo hooks from React
import { useParams } from "react-router-dom"; // Import the useParams hook from react-router-dom
import cdetails from "../../assets/images/others/shape-79.png"; // Import the shape-79 image
import cshape from "../../assets/images/svg-icons/books.svg"; // Import the books icon
import cshape1 from "../../assets/images/cta/shape-08.png"; // Import the shape-08 image
import cshape2 from "../../assets/images/others/shape-65.png";  // Import the shape-65 image
import cshape3 from "../../assets/images/others/shape-81.png";  // Import the shape-81 image
import { useBackgroundContext } from "../../Context/BackgroundChange"; // Import the useBackgroundContext
import { useButtonDetailsContext } from "../../Context/Pagebuttonmanagement";  // Import the useButtonDetailsContext
import CustomEnquery from "../Form/CustomEnquery";  // Import the CustomEnquery component
import SEO from "../SeoWidget/SEO/SEO";   // Import the SEO component
import { useTestContext } from "../../Context/Testpi"; // Import the useTestContext
import UseAnalytics from "../GoogleAnalytics/UseAnalytics"; // Import the UseAnalytics component
const  ButtonDetails = React.lazy(() => import("../ButtonDetails/ButtonDetails"));  // Lazy load the ButtonDetails component
const  Courses = React.lazy(() => import("../Card/Coursescard")); // Lazy load the Courses component
const  Keywords = React.lazy(() => import("../Keywords/keywords")); // Lazy load the Keywords component

function Testdetails() { 
  const [filterbyBranch, setFilterbyBranch] = useState([]); // Initialize the filterbyBranch state variable
  const DetailscomponentName1 = "CourseDetailsButton";  // Set the DetailscomponentName1 to "CourseDetailsButton"
  const DetailscomponentName = "BatchCategory"; // Set the DetailscomponentName to "BatchCategory"
  // const DetailscomponentName = ""; 
  const { testurl } = useParams(); // Extracts branchUrl from the URL
  const {
    testDetails,
    BatchbyTest,
    loading,
    error,
    fetchTestByUrlHandler,  
  } = useTestContext(); // Destructure the testDetails, BatchbyTest, loading, error, and fetchTestByUrlHandler from the useTestContext

  const { backgroundStyle, changeimagehandler } = useBackgroundContext(); // Destructure the backgroundStyle and changeimagehandler from the useBackgroundContext

  const {buttonDetailsState} = useButtonDetailsContext();  

    // Get the state specific to this component ID
    const { buttonDetailsNames = [], activeDetailsButton = '' } =
    buttonDetailsState["CourseDetailsButton"] || {};

  // const { Content, setContent } = useContentContext();

  const { batchselectedid } = useButtonDetailsContext();
  console.log(batchselectedid);

  useEffect(() => {
    fetchTestByUrlHandler(testurl); // Fetch branches on component mount
  }, [testurl]);

  // Delay changeimagehandler until coursesDetails.pic is available
  useEffect(() => {
    // if (coursesDetails?.pic) {
    const timeoutId = setTimeout(() => {
      // changeimagehandler(coursesDetails.pic);
    }, 500); // Delay for 300ms (adjust as needed)

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount or change
    // }
  }, [testDetails.pic]);

  useEffect(() => {
    console.log("clling this");
    if (Array.isArray(BatchbyTest) && batchselectedid) {
      const filteredBatches = BatchbyTest.filter(
        (batch) => batch.branchid === batchselectedid
      );
      setFilterbyBranch(filteredBatches);
    }
  }, [batchselectedid, BatchbyTest]);

    //   useEffect(()=>{
    //   setContent(coursesDetails?.details)
    // },[coursesDetails?.details])

  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error loading course details. {error}</p>;

  const CourseName = testDetails?.coursename || "Course Name";  // Set the CourseName to the testDetails coursename or "Course Name"
  const CoursePic = testDetails?.pic || cdetails; // Set the CoursePic to the testDetails pic or the cdetails image
  const CourseDuration = testDetails?.duration || "0";  // Set the CourseDuration to the testDetails duration or "0"
  const detailsHTML = testDetails?.details || ""; // Set the detailsHTML to the testDetails details or an empty string
  const syllabusHTML = testDetails?.syllabus || ""; // Set the syllabusHTML to the testDetails syllabus or an empty string
  const learnitHTML = testDetails?.learnit || ""; // Set the learnitHTML to the testDetails learnit or an empty string
  const Batch = testDetails?.totalBatch || "";    // Set the Batch to the testDetails totalBatch or an empty string
  const brochure1 = testDetails?.brochure1 || "No link";  // Set the brochure1 to the testDetails brochure1 or "No link"
  const branchname = testDetails?.branchNames || [];  // Set the branchname to the testDetails branchNames or an empty array
  const metatitle = testDetails?.metatitle || ''; // Set the metatitle to the testDetails metatitle or an empty string
  const metadescription = testDetails?.metadescription || ''; // Set the metadescription to the testDetails metadescription or an empty string

  const keywordsArray = testDetails?.keywords ? testDetails.keywords.split(",").map((keyword) => keyword.trim()) : [];  // Set the keywordsArray to the testDetails keywords or an empty array




  // const filterbyBranch = [];
  // useEffect(()=>{
  //   filterbyBranch = BatchbyCourse.filter(batch => batch.branchid === batchselectedid)
  // },[batchselectedid])

  return (
    <>
    <UseAnalytics />  {/* Use the Google Analytics component */}
    <SEO seot1={metatitle} seot2={metadescription}/>  {/* Use the SEO component */}
      <div className="edu-breadcrumb-area breadcrumb-style-8">
        <div className="breadcrumb-right-wrapper" style={backgroundStyle}>  
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="page-title">
                <h2 className="title custom-heading">{CourseName}</h2>  {/* Set the CourseName */}
              </div>
              <ul className="course-meta custom-box">
                {branchname.map((branch, index) => (
                  <li key={index}>
                    <i className="icon-59"></i> {branch}
                  </li>
                ))} {/* Map the branchname */}
              </ul>
            </div>
          </div>
        </div>
        <ul className="shape-group">
          <li className="shape-6 scene">
            <img data-depth="2" src={cshape3} alt="shape" />
          </li>
          <li className="shape-7 scene">
            <img data-depth="-2" src={cshape2} alt="shape" />
          </li>
          <li className="shape-8">
            <img src={cshape1} alt="shape" />
          </li>
          <li className="shape-9 scene">
            <img data-depth="-2" src={cshape} alt="shape" />
          </li>
        </ul>
      </div>
      <section className="edu-section-gap course-details-area">
        <div className="container">
          <div className="row row--30">
            <div className="col-lg-8">
              <div className="course-details-content course-details-7">
              <Suspense fallback={<p>This is Loading. branch</p>}>
                <ButtonDetails DetailscomponentID={DetailscomponentName1} DetailscomponentName={DetailscomponentName1} defaultLabel={""} /> 
</Suspense> 
                <div className="tab-content" id="myTabContent">
        {buttonDetailsNames.map((button) => (
          <div
            key={button.id}
            className={`tab-pane fade ${activeDetailsButton === button.label ? 'show active' : ''}`}
            id={button.id}
            role="tabpanel"
            aria-labelledby={`${button.id}-tab`}
          > 
            <div className="course-tab-content">
              <div className="course-overview">
                {/* Render content dynamically */}
                {/* {b} */}
                {button.label === 'Overview' && <p dangerouslySetInnerHTML={{ __html: detailsHTML }} />}
                {button.label === 'Syllabus' && <p dangerouslySetInnerHTML={{ __html: syllabusHTML }} />}
                {button.label === 'What You’ll Learn' && <p dangerouslySetInnerHTML={{ __html: learnitHTML }} />}
              </div>
            </div>
          </div>
        ))}
      </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="course-sidebar-3 sidebar-top-position">
                <div className="edu-course-widget widget-course-summery">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src={CoursePic} alt="Courses" />
                    </div>
                    <div className="content">
                      <h4 className="widget-title custom-heading1">
                        {CourseName}
                      </h4>
                      <ul className="course-item">
                        <li>
                          <span className="label">
                            <i className="icon-61"></i>Duration:
                          </span>
                          <span className="value">{CourseDuration}</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-62"></i>Batches:
                          </span>
                          <span className="value">{Batch}</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-59"></i>Branches:
                          </span>
                          <span className="value">{branchname.length}</span>
                        </li>
                        <li>
                          <span className="label">
                            <i className="icon-65"></i>Brochure:
                          </span>
                          <span className="value">{brochure1}</span>
                        </li>
                      </ul>
                      <div className="read-more-btn">
                        <a href="#coursebatchsection" className="text-white edu-btn">
                          View Batches<i className="icon-4"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <CustomEnquery /> {/* Use the CustomEnquery component */}

                <div className="col-lg-12 mt-5 pt-5">
                  <div className="contact-form form-style-2 px-0 py-0">
                    <div className="mt-5">
                    <Suspense fallback={<p>This is Loading. branch</p>}>
                      <Keywords keyword={keywordsArray} />  {/* Use the Keywords component */}
                      </Suspense> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="gap-bottom-equal" id="coursebatchsection" style={{ paddingBottom: "5px" }}> {/* Set the id to "coursebatchsection" */}
        <div className="edu-course-area course-area-1 edu-section-gap bg-lighten01">  {/* Set the background color */}
          <div className="container"> 
            <div className="section-title section-center">
              <span className="pre-title">Popular Batch</span>  {/* Set the pre-title */}
              <h2 className="title">Pick A Batch To Get Started</h2>  {/* Set the title */}
              <span className="shape-line">
                <i className="icon-19"></i>   {/* Set the icon */}
              </span>
              <div>
                <div className="course-details-content pt-4">
                <Suspense fallback={<p>This is Loading. branch</p>}>  
                  <ButtonDetails DetailscomponentID={DetailscomponentName} DetailscomponentName={DetailscomponentName} defaultLabel={""} /> {/* Use the ButtonDetails component */} 
                  </Suspense> 
                </div>
              </div>
            </div>
          </div>
          <div className="container">
          <Suspense fallback={<p>This is Loading. branch</p>}>  
            <Courses batchs={filterbyBranch} branchname={branchname} /> {/* Use the Courses component */}
            </Suspense> 
          </div>
        </div>
      </div>
    </>
  );
}

export default Testdetails;
