import React from 'react';

function Overview() {
  return (
    <>
    <div
                    className="tab-pane fade show active"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                  >
                    <div className="course-tab-content">
                      <div className="course-overview">
                      <div className="course-tab-content">
                      <div className="course-overview">
                        <h3 className="heading-title">Course Description</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor inc idid unt ut labore et
                          dolore magna aliqua enim ad minim veniam, quis nostrud
                          exerec tation ullamco laboris nis aliquip commodo
                          consequat duis aute irure dolor in reprehenderit in
                          voluptate velit esse cillum dolore eu fugiat nulla
                          pariatur enim ipsam.
                        </p>
                        <p className="mb--60">
                          Excepteur sint occaecat cupidatat non proident sunt in
                          culpa qui officia deserunt mollit anim id est laborum.
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium totam rem
                          aperiam.
                        </p>
                        <h5 className="title">What You’ll Learn?</h5>
                        <ul className="mb--60">
                          <li>
                            Learn to use Python professionally, learning both
                            Python 2 & Python 3!
                          </li>
                          <li>
                            Build 6 beautiful real-world projects for your
                            portfolio (not boring toy apps)
                          </li>
                          <li>
                            Understand the Theory behind Vue.js and use it in
                            Real Projects
                          </li>
                          <li>
                            Create responsive, accessible, and beautiful layouts
                          </li>
                        </ul>
                        <p>
                          Consectetur adipisicing elit, sed do eiusmod tempor
                          inc idid unt ut labore et dolore magna aliqua enim ad
                          minim veniam quis nostrud exerec tation ullamco
                          laboris nis aliquip commodo consequat duis aute irure
                          dolor.
                        </p>
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
    </>
  )
}

export default Overview;