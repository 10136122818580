import { createContext, useState, useContext, useRef, useEffect } from "react";

const ToggleContext = createContext();

export function ToggleProvider({ children }) {
  const [isVisible, setIsVisible] = useState("see");
  const [isMobileVisible, setIsMobileVisible] = useState("see");
  const [mobilemenuid, setmobilemenuid] = useState(null);

  // Function to handle the navigation click event
  const TogglecloseHandler = () => {
    setIsVisible("hide");
  };
  const ToggleopenHandler = () => {
    console.log("i am working");
    setIsVisible("see");
  };
  const TogglemobileopenHandler = () => {
    console.log("i am working");
    setIsMobileVisible("see");
  }
  const TogglemobilecloseHandler = () => {
    console.log("i am not working");
    setIsMobileVisible("hide");
  }

  const menuidhandler = (id) => {
     if(mobilemenuid === null){
       setmobilemenuid(id);
    }
    else{
       if(mobilemenuid === id){
         setmobilemenuid(null)
       }
       else if(mobilemenuid !== id){
        setmobilemenuid(id);
       }

     }
  };

  return (
    <ToggleContext.Provider
      value={{isMobileVisible, isVisible, mobilemenuid, TogglecloseHandler, ToggleopenHandler, menuidhandler,TogglemobileopenHandler, TogglemobilecloseHandler}}
    >
      {children}
    </ToggleContext.Provider>
  );
}

export function useToggleContext() {
  return useContext(ToggleContext);
}
