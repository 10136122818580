import React from 'react';
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useScrollingManagementContext } from '../../../Context/ScrollingEventManagement';

function MobileFooterNavbar() {
    const { ScrollingopenHandler } = useScrollingManagementContext();


  return ( 
    <>
    <div className='responsive-footer-nav-bar bg-light fixed-bottom py-1'>
                <div className='d-flex align-items-center justify-content-between'>
                    <Link to="/"  onClick={() => ScrollingopenHandler()} className='flex-grow-1 d-flex justify-content-center align-items-center border-0'>
                        <div className='fn1 d-flex flex-column align-items-center py-1'>
                        <FaHome className='fw-bold text-danger' style={{fontSize:'2.5rem'}} />
                            <p className='text-danger fs-6 my-0' style={{fontWeight:500}}>HOME</p>
                        </div>
                    </Link>
                    <Link to="https://student.sriramsias.com/"  onClick={() => ScrollingopenHandler()} className='flex-grow-1 d-flex justify-content-center align-items-center border-0'>
                        <div className='fn2 d-flex flex-column align-items-center py-1'>
                            <i className='icon-48 text-danger fw-bold' style={{fontSize:'2.5rem'}}></i>
                            <p className='text-danger fs-6 my-0' style={{fontWeight:500}}>STUDENT LOGIN</p>
                        </div>
                    </Link>
                    <Link to="/registration"  onClick={() => ScrollingopenHandler()} className='flex-grow-1 d-flex justify-content-center align-items-center border-0'>
                        <div className='fn3 d-flex flex-column align-items-center py-1'>
                            <i className='icon-24 text-danger fw-bold' style={{fontSize:'2.2rem'}}></i >
                            <p className='text-danger fs-6 my-0' style={{fontWeight:500}}>ENROLL NOW</p>
                        </div>
                    </Link>
                    <Link to="/contact-us"  onClick={() => ScrollingopenHandler()} className='flex-grow-1 d-flex justify-content-center align-items-center border-0'>
                        <div className='fn4 d-flex flex-column align-items-center py-1'>
                            <i className='icon-29 text-danger fw-bold' style={{fontSize:'2.2rem'}}></i>
                            <p className='text-danger fs-6 my-0' style={{fontWeight:500}}>CONTACT</p>
                        </div>
                    </Link>
                </div>
            </div>
    </>
  )
}

export default MobileFooterNavbar