import React, { useEffect, useState } from "react";
import useEduJs from "../../Hooks/useEduJs1";
import MobileSingleNabar from "../NavbarDropdown/MainMenu/MobileMenu";
import SingleNabar from "../NavbarDropdown/SingleNabar/newSingleNavbar copy";
import MainMenu from "../NavbarDropdown/MainMenu/MainMenu";
import logoimg from "../../Logo-1.png";
import { useWebdetailsContext } from "../../Context/WebDetails";
import { useNewsheadlineContext } from "../../Context/NewsHeadline";
import { NavProvider } from "../../Context/Navbarapi";
import { useToggleContext } from "../../Context/Togglemanagement";
import { Link } from "react-router-dom";
import GetCall from "../GetCall";
import { useScrollingManagementContext } from "../../Context/ScrollingEventManagement";
import FAQdrodown from "../FAQ/FAQdropdown";
import MainMenu1 from "../NavbarDropdown/MainMenu/MainMenu1";
import FloatingButton from "../FloatingButton";
import TawkToChat from "../TawkTochat/TawkTochat";
import { useTrackingManagementContext } from "../../Context/TrackingManagement";
import { useQueryContext } from "../../Context/QueryManagement";
import { useBranchwiseidContext } from "../../Context/BranchwiseCourse";

function Header() { 
  const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
    useWebdetailsContext();
  const { newsheadline, fetchNewsheadlineByUrlHandler } =
    useNewsheadlineContext();

    const { trackingDataValue, trackingid, handleButtonClick } = useTrackingManagementContext();
        
          const { getPagename, pagename } = useQueryContext();
              
                useEffect(() =>{
                  getPagename("Home")
                   },[])

  const { ToggleopenHandler, isVisible } = useToggleContext();

  const { ScrollingopenHandler } = useScrollingManagementContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);  // Track viewport width


  useEffect(() => { 
    fetchWebdetailsByUrlHandler(); // Fetch branches on component mount
    fetchNewsheadlineByUrlHandler();

    // Handle window resize events to update isMobile state
    const handleResize = () => setIsMobile(window.innerWidth < 600);
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);

  }, []); 

  const Mailid = Webdetails?.adminemail || "sriramsias@gmail.com";
  const phone = Webdetails?.callnumber || "";
  const flink = Webdetails?.facebook || "";
  const ilink = Webdetails?.instagram || "";
  const tlink = Webdetails?.twitter || "";
  const ylink = Webdetails?.youtube || "";
  const dynamiclogo = Webdetails?.logo || "";
  const getacall_display = Webdetails?.getacall_display || "";
  const needupsc_display = Webdetails?.needupsc_display || "";
  const tawk_display = Webdetails?.tawk_display|| "";
  const noticeboard = Webdetails?.noticeboard || "";
  const parts = noticeboard.split(". "); // Split the string by the period followed by a space
  const { stickyPlaceholderRef, menuRef } = useEduJs();

    // Check if pathname includes '/course-details'
  const isCourseDetailsPage = window.location.pathname.includes("/course-details");
  return (
    <>
      <header
        className="edu-header header-style-1 header-fullwidth"
        id="header"
      >
        <div
          className="header-top-bar"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div className="container-fluid">
            <div className="header-top">
              <div className="text-center col-lg-12">
                <div className="header-notify d-flex align-items-center col-lg-12">
                  <span>
                    <img
                      src="https://admin.sriramsias.com/images/arrow.gif"
                      style={{ width: "30px", height: "20px" }}
                      alt=""
                    />
                  </span>
                  <div className="marqueeContainer col-lg-12">
                    <div className="marqueeContent">
                      {newsheadline.map((line, index) => (
                        <Link id={trackingid} data-button-id={`Click on the button '${line.headline}' on the ${pagename} Page at URL: ${window.location.href}.`} to={line.linkurl}
                          key={index}
                          className="marqueeText text-white"
                        >
                        {line.icon ?<img src= {line.icon} alt=""/> : <i className="icon-81"></i>} {line.headline}
                        </Link>
                      ))}

                      {/* Add more items as needed */}
                    </div>
                  </div>

                  {getacall_display === 1 &&<GetCall/>   }     
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-top-bar">
          <div className="container-fluid">
            <div className="header-top">
              <div className="header-top-left">
                <div className="wave-text">{noticeboard}</div>
              </div>
              <div className="header-top-right">
                <ul className="header-info"> 
                  <li>
                    <Link id={trackingid} data-button-id={`Click on the button student login on the ${pagename} Page at URL: ${window.location.href}.`}
                      to="https://student.sriramsias.com/"
                      style={{ fontSize: "12px" }}
                    >
                      STUDENT LOGIN
                    </Link>
                  </li>
                  <li>
                    <Link id={trackingid} data-button-id={`Click on the button registration on the ${pagename} Page at URL: ${window.location.href}.`} to="/registration" style={{ fontSize: "12px" }}>
                      ENROLL NOW
                    </Link>
                  </li>
                  <li>
                    <Link id={trackingid} data-button-id={`Click on the button call on the ${pagename} Page at URL: ${window.location.href}.`} to={`tel:+91${phone}`}>
                      <i className="icon-phone"></i>Call: {phone}
                    </Link>
                  </li>
                  <li> 
                    <Link id={trackingid} data-button-id={`Click on the button mail on the ${pagename} Page at URL: ${window.location.href}.`}
                      to={`mailto:${Mailid}`}
                      target="_blank"
                      style={{ fontSize: "15px" }}
                    >
                      <i className="icon-envelope"></i>
                      {Mailid}
                    </Link>
                  </li>
                  <li className="social-icon">
                    <a href={`${flink}`}>
                      <i className="icon-facebook"></i>
                    </a>
                    <a href={`${ilink}`}>
                      <i className="icon-instagram"></i>
                    </a>
                    <a href={`${tlink}`}>
                      <i className="icon-twitter"></i>
                    </a>
                    <a href={`${ylink}`}>
                      <i className="icon-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="edu-sticky-placeholder" ref={stickyPlaceholderRef}></div>
        <div className="header-mainmenu" ref={menuRef}>
          <div className="container-fluid">
            <div className="header-navbar responsive-custom-header-navbar">
              <div className="header-brand responsive-custom-header-brand">
                <div className="responsive-custom-header-logo-cantainer">
                <div className="logo responsive-custom-header-logo">
                  <Link onClick={() => ScrollingopenHandler()} to="/">
                    <img
                      className="logo-light"
                      src={dynamiclogo}
                      onError={(e) => {
                        e.target.src = logoimg;
                      }}
                      alt="SRIRAM's IAS"
                    />
                  </Link>
                </div> 
                </div>
                <div className="responsive-custom-header-category-container">
                  <div className="header-category">
                    <nav className="mainmenu-nav">
                      <ul className="mainmenu">
                        <li
                          onClick={() => ScrollingopenHandler()}
                          className="has-droupdown menu-item-has-children"
                          style={{
                            fontFamily: "Helvetica",
                            marginRight: "10px",
                          }}
                        >
                          <Link to="/course" onMouseOver={() => ToggleopenHandler()} className="responsive-custom-header-course-link dropdown dropdown-toggle py-0" style={{lineHeight: "90px"}}>
                          {/* <Link to="#" className="responsive-custom-header-course-link"> */}
                            
                          <div className="dropdown bg-danger py-0">
      <button className="dropdown-bs-toggle fw-bold py-0 my-0" style={{paddingBottom: "-5px"}}>
      <i className="icon-1"></i> COURSES
      </button>
                          <SingleNabar /> 
                          <MobileSingleNabar /> 
                          </div>
                          {/* COURSES */} 
                          </Link>
                        </li>
                      </ul> 
                    </nav>
                  </div>
                  <div className="header-right">
                    <ul className="header-action">
                      <li className="mobile-menu-bar d-block d-xl-none">
                        <button className="hamberger-button">
                          <i className="icon-54"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="header-mainnav">
                <nav className="mainmenu-nav">
                  <NavProvider>
                    <MainMenu />
                  </NavProvider>
                </nav>
              </div>
            </div>
          </div>
        </div>


        <div className="popup-mobile-menu">
          <div className="inner">
            <div className="header-top">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-light"
                    src={dynamiclogo}
                    onError={(e) => {
                      e.target.src = logoimg;
                    }}
                    alt="SRIRAM's IAS"
                  />
                  <img
                    className="logo-dark"
                    src={dynamiclogo}
                    onError={(e) => {
                      e.target.src = logoimg;
                    }}
                    alt="SRIRAM's IAS"
                  />
                </a>
              </div>
              <div className="close-menu">
                <button className="close-button">
                  <i className="icon-73"></i>
                </button>
              </div>
            </div>  
            <NavProvider>
              <MainMenu1 />
            </NavProvider>

          </div>
        </div>

        {/* <!-- Start Search Popup  --> */}
        <div className="edu-search-popup">
          <div className="content-wrap">
            <div className="site-logo">
              <img
                className="logo-light"
                src="assets/images/logo/logo-dark.png"
                alt="Corporate Logo"
              />
              <img
                className="logo-dark"
                src="assets/images/logo/logo-white.png"
                alt="Corporate Logo"
              />
            </div>
            <div className="close-button">
              <button className="close-trigger">
                <i className="icon-73"></i>
              </button>
            </div>
            <div className="inner">
              <form className="search-form" action="#">
                <input
                  type="text"
                  className="edublink-search-popup-field"
                  placeholder="Search Here..."
                />
                <button className="submit-button">
                  <i className="icon-2"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- End Search Popup  --> */}
      </header>

{  isMobile && isCourseDetailsPage ? '' : needupsc_display === 1 && <FloatingButton /> }
                                          {isMobile && isCourseDetailsPage ? '' : tawk_display === 1 && <TawkToChat/>}
    </>
  );
}

export default Header;
