import { createContext, useState, useContext } from 'react';
import {fetchbooks, fetchepics} from '../Config/api';

const BooksContext = createContext();

export function BooksProvider({ children }) {
    const [booksDetails, setBooksDetails] = useState([]);
    const [epicDetails, setEpicDetails] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

        // Fetch single Books data based on URL 
        const fetchBooksByUrlHandler = async () => {
          setLoading(true);
          try {
              const data = await fetchbooks();
              setBooksDetails(data.data)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      };

       // Fetch single Epic magazine data based on URL
        const fetchepicmagazineByUrlHandler = async () => {
          setLoading(true);
          try {
              const data = await fetchepics();
              setEpicDetails(data.data)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      };
  
    return (
      <BooksContext.Provider value={{ booksDetails, epicDetails, loading, error,fetchBooksByUrlHandler, fetchepicmagazineByUrlHandler}}>
        {children}
      </BooksContext.Provider>
    );
  }
  
  export function useBooksContext() {
    return useContext(BooksContext);
  }