import { createContext, useState, useContext } from 'react';
import {fetchGeneralEssay} from '../Config/api';
import { useNotFoundContext } from './NotFound';
import { useStatuscodeContext } from './StatusCode';

const GeneralEssayContext = createContext();

export function GeneralEssayProvider({ children }) {
    const [generalessay, setGeneralessay] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const { pagefoundhandler } = useNotFoundContext();  
    const { statuscodehandler } = useStatuscodeContext(); 
   
      // Fetch all blogs data
      const fetchAllGeneralHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchGeneralEssay();
            setGeneralessay(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };

    return (
      <GeneralEssayContext.Provider value={{generalessay, loading, error, fetchAllGeneralHandler}}>
        {children}
      </GeneralEssayContext.Provider>
    );
  }
  
  export function useGeneralEssayContext() {
    return useContext(GeneralEssayContext);
  }