import { createContext, useState, useContext } from 'react';
import {fetchAllNewsheadline} from '../Config/api';

const NewsheadlineContext = createContext();

export function NewsheadlineProvider({ children }) {
    const [newsheadline, setNewsheadline] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   

    // Fetch single article data based on URL 
    const fetchNewsheadlineByUrlHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchAllNewsheadline();
            setNewsheadline(data.data)
            console.log(data)
            // return data.data; // Return branch data from response
        } catch (err) {
            console.error("Error fetching branch by URL:", err);
            setError("Failed to load branch by URL");
            return null;
        } finally {
            setLoading(false);
        }
    }; 
  
    return (
      <NewsheadlineContext.Provider value={{ newsheadline , loading, error , fetchNewsheadlineByUrlHandler }}>
        {children}
      </NewsheadlineContext.Provider>
    );
  }
  
  export function useNewsheadlineContext() {
    return useContext(NewsheadlineContext);
  }