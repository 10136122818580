import React, { useEffect, Suspense } from 'react';
import { useBranchContext } from '../../../Context/Branchapi';

// Lazy load the BranchComponentcard for optimized performance
const BranchComponentcard = React.lazy(() => import("../../BranchComponentcard/BranchComponentcard"));

function CommonPageBranchSection() {
    // Destructure the branches and the fetch handler from Branch context
    const { branches, fetchAllBranchesHandler } = useBranchContext();

    // Fetch branch data when the component mounts
    useEffect(() => {
        fetchAllBranchesHandler();
    }, []);

    return (
        <>
            {/* Main container for the branch section */}
            <div className="edu-blog-area blog-area-1 edu-section-gap bg-lightenharsh" style={{ padding: "30px 0px 45px" }}>
                <div className="container">
                    {/* Section title */}
                    <div className="section-title section-center">
                        <span className="pre-title custom-font-family">OUR BRANCHES</span>
                        <h2 className="title custom-font-family">Study with SRIRAM's IAS</h2>
                        <span className="shape-line"><i className="icon-19"></i></span>
                    </div>

                    {/* Branch card grid */}
                    <div className="row g-5">
                        {branches.slice(1).map((blogItem, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-12">
                                {/* Lazy load each branch card */}
                                <Suspense fallback={<p>This is Loading. branch</p>}>
                                    <BranchComponentcard param={blogItem} />
                                </Suspense>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Decorative shapes for the design */}
                <ul className="shape-group">
                    <li className="shape-1 scene">
                        <img data-depth="-1.4" src="assets/images/about/shape-02.png" alt="Shape" />
                    </li>
                    <li className="shape-2 scene">
                        <span data-depth="2.5"></span>
                    </li>
                    <li className="shape-3 scene">
                        <img data-depth="-2.3" src="assets/images/counterup/shape-05.png" alt="Shape" />
                    </li>
                </ul>
            </div>
        </>
    );
}

export default CommonPageBranchSection;
