import { createContext, useContext } from 'react';

const ScrollingManagementContext = createContext();

export function ScrollingManagementProvider({ children }) {
  const ScrollingopenHandler = () =>{
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <ScrollingManagementContext.Provider value={{ ScrollingopenHandler }}>
      {children}
    </ScrollingManagementContext.Provider>
  );
}

export function useScrollingManagementContext() {
  return useContext(ScrollingManagementContext);
}
