import { createContext, useState, useContext } from 'react';
import { fetchHomepageContent } from '../Config/api';

const HomeContext = createContext();

export function HomeProvider({ children }) {
    const [Homecontent, setHomecontent] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   
      // Fetch all blogs data
      const fetchHomeContentHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchHomepageContent();
            setHomecontent(data.data[0]); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };
  
    return (
      <HomeContext.Provider value={{ Homecontent, loading, error, fetchHomeContentHandler}}>
        {children}
      </HomeContext.Provider>
    );
  }
  
  export function useHomeContext() {
    return useContext(HomeContext);
  }