import React, { useEffect } from "react";

const TawkToChat = () => {
  useEffect(() => {
    // Ensure Tawk_API is available in the global scope
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Dynamically create and load the Tawk.to script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/5f0bee1467771f3813c0e9a3/default"; // Replace with your Tawk.to Property ID
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);

    // Cleanup function to remove script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // No UI is rendered by this component
};

export default TawkToChat;
