import { createContext, useState, useContext } from 'react';
import {fetchAllWebdetails} from '../Config/api';

const WebdetailsContext = createContext();

export function WebdetailsProvider({ children }) {
    const [Webdetails, setWebdetails] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   

    // Fetch single article data based on URL 
    const fetchWebdetailsByUrlHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchAllWebdetails();
            setWebdetails(data.data[0])
            console.log(data)
            // return data.data; // Return branch data from response
        } catch (err) {
            console.error("Error fetching branch by URL:", err);
            setError("Failed to load branch by URL");
            return null;
        } finally {
            setLoading(false);
        }
    }; 
  
    return (
      <WebdetailsContext.Provider value={{ Webdetails , loading, error , fetchWebdetailsByUrlHandler }}>
        {children}
      </WebdetailsContext.Provider>
    );
  }
  
  export function useWebdetailsContext() {
    return useContext(WebdetailsContext);
  }