import { createContext, useState, useContext } from 'react';

const StatuscodeContext = createContext();

export function StatuscodeProvider({ children }) {
    const [isstatuscode, setIsstatuscode] = useState(200);

    const statuscodehandler = (ispagevalue) =>{
        setIsstatuscode(ispagevalue)
    }    
  
    return (
      <StatuscodeContext.Provider value={{ isstatuscode, statuscodehandler }}>
        {children}
      </StatuscodeContext.Provider>
    );
  }
  
  export function useStatuscodeContext() {
    return useContext(StatuscodeContext);
  }