import { createContext, useContext } from 'react';

const ValidateUrlContext = createContext();

export function ValidateUrlProvider({ children }) {

    function validateURL(url) {
        const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
        return urlRegex.test(url);
      }
  
    return (
      <ValidateUrlContext.Provider value={{validateURL}}>
        {children}
      </ValidateUrlContext.Provider>
    );
  }
  
  export function useValidateUrlContext() {
    return useContext(ValidateUrlContext);
  }